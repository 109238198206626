import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Header from "../Header";
import Footer from "../Footer";
import { Outlet } from 'react-router-dom';
import Section from '../Section';
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import Alert from "./Alert";
import { pageTitle } from "../../helpers/PageTitle";
import { Button, Spinner } from "react-bootstrap"; // Import Button and Spinner
function PatientProfile({ account, healthcareDAppContract, roleManagementContract }) {
  pageTitle("Patient Dashboard");
  const [patientProfile, setPatientProfile] = useState(null);
  const [visibleSection, setVisibleSection] = useState(null);
  const [authorizedPharmacists, setAuthorizedPharmacists] = useState([]);
  const [loadingPharmacists, setLoadingPharmacists] = useState(false); 
  const [prescriptions, setPrescriptions] = useState([]);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [newProblem, setNewProblem] = useState("");
  const [tests, setTests] = useState([]);
  const [currentBillId, setCurrentBillId] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [bills, setBills] = useState([]);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [doctors, setDoctors] = useState([]);
  const [authorizedDoctor, setAuthorizedDoctor] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortAscending, setSortAscending] = useState(false);
  const [selectedTestIndex, setSelectedTestIndex] = useState(null);
  const [authorizedProviders, setAuthorizedProviders] = useState([]);
  const [pastMedicalRecords, setPastMedicalRecords] = useState([]);
  const [loadingPastRecords, setLoadingPastRecords] = useState(false);
  const [pharmacistLocations, setPharmacistLocations] = useState({});
  const [orderedTests, setOrderedTests] = useState([]); // For storing ordered tests
const [selectedTestIndexes, setSelectedTestIndexes] = useState([]); // For selected test indexes
const [loading, setLoading] = useState(false); // For loading state
const [labBills, setLabBills] = useState([]);


  useEffect(() => {
    if (account) {
      fetchPharmacistLocations();
    }
  }, [account]);

  // Call fetchPatientProfile only after pharmacistLocations are fetched
  useEffect(() => {
    if (account && Object.keys(pharmacistLocations).length > 0) {
      fetchPatientProfile();
    }
  }, [account, pharmacistLocations]);

  // Function to show alert
  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
  };

  // Fetch pharmacist locations and map them by address
  async function fetchPharmacistLocations() {
    try {
      const pharmacistData = await roleManagementContract.viewAllAuthorizedPharmacists();
      const locationMap = {};

      pharmacistData.forEach(pharmacist => {
        const [name, address, location] = pharmacist;
        locationMap[address] = { name, location };
      });

      setPharmacistLocations(locationMap);
      showAlert("Pharmacist locations fetched successfully.", "success");
    } catch (error) {
      console.error("Error fetching pharmacist locations:", error);
      showAlert("Unable to fetch pharmacist locations.", "danger");
    }
  }

  async function fetchPatientProfile() {
    try {
      const profile = await healthcareDAppContract.viewProfileNoParameters();
      const formattedProfile = {
        name: profile[0],
        number: profile[1],
        date: profile[2],
        age: profile[3].toString(),
        gender: profile[4],
        problem: profile[5],
      };

      const formattedPrescriptions = profile[6]?.map((prescription) => ({
        id: prescription[0].toString(),
        medicine: prescription[1],
        dosage: prescription[2],
        fulfilled: prescription[3] ? "Yes" : "No",
        doctorAddress: prescription[4],
        timestamp: new Date(prescription[5] * 1000).toLocaleString(),
        doctorName: prescription[6],
      })) || [];

      const formattedRecords = profile[7]?.map((record) => ({
        description: record[0],
        doctor: record[1],
        timestamp: new Date(record[2] * 1000).toLocaleString(),
        doctorAddress: record[3],
      })) || [];

      setPatientProfile(formattedProfile);
      
      setPrescriptions(formattedPrescriptions);
      setMedicalRecords(formattedRecords);

      const patientBills = await healthcareDAppContract.viewBills();
      const formattedBills = patientBills.map((bill, originalIndex) => ({
        billingId: bill.billingId ? bill.billingId.toString() : originalIndex.toString(),
        originalIndex, // Include original index
        description: bill.description,
        amount: ethers.utils.formatEther(bill.amount),
        isPaid: bill.isPaid,
        pharmacistName: bill.pharmacyName,
        location: bill.location,
      }));
      setBills(formattedBills);
      

      showAlert("Patient profile fetched successfully.", "success");
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Patient not found or access denied.";
      console.error("Error fetching patient profile:", error);
      showAlert(errorReason, "danger");
    }
  }

  

  // Fetch available doctors for updating or changing doctor
  async function fetchAvailableDoctors() {
    try {
      const doctorsList = await roleManagementContract.viewAllAuthorizedDoctors();
      const formattedDoctors = doctorsList.map((doctor) => ({
        name: doctor[0],
        specialization: doctor[2],
        address: doctor[6],
      }));
      setDoctors(formattedDoctors);
      showAlert("Available doctors fetched successfully.", "success");
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Unable to fetch doctors.";
      console.error("Error fetching doctors:", error);
      showAlert(errorReason, "danger");
    }
  }
  async function fetchPastMedicalRecords() {
    try {
      setLoadingPastRecords(true);
      const records = await healthcareDAppContract.viewPatientHistory();
      
      const formattedRecords = records[0].map((_, index) => ({
        healthProblem: records[0][index],
        medicine: records[1][index],
        dosage: records[2][index],
        fulfilled: records[3][index] ? "Yes" : "No",
        doctorName: records[4][index],
        timestamp: new Date(records[5][index] * 1000).toLocaleString()
      }));

      setPastMedicalRecords(formattedRecords);
      showAlert("Past medical records fetched successfully.", "success");
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Unable to fetch past medical records.";
      console.error("Error fetching past medical records:", error);
      showAlert(errorReason, "danger");
    } finally {
      setLoadingPastRecords(false);
    }
  }

  // Function to update the health problem and doctor
  async function updateProblemAndDoctor() {
    try {
      const tx = await healthcareDAppContract.updateProblemAndDoctor(newProblem, selectedDoctor);
      await tx.wait();
      showAlert("Health problem and doctor updated successfully!", "success");
      fetchPatientProfile();
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Error updating health problem and doctor.";
      console.error("Error updating health problem and doctor:", error);
      showAlert(errorReason, "danger");
    }
  }

  // Grant access to a doctor and refresh authorized providers list
  async function grantDoctorAccess(doctorAddress) {
    try {
      const tx = await healthcareDAppContract.grantDoctorAccess(doctorAddress);
      await tx.wait();
      showAlert(`Access successfully granted to doctor: ${doctorAddress}`, "success");
      await fetchAuthorizedProviders();
    } catch (error) {
      const errorReason = error?.data?.data?.reason || `Error granting access to doctor: ${doctorAddress}.`;
      console.error("Error granting doctor access:", error);
      showAlert(errorReason, "danger");
    }
  }

  // Revoke access from a doctor and refresh authorized providers list
  async function revokeDoctorAccess(doctorAddress) {
    try {
      const tx = await healthcareDAppContract.revokeDoctorAccess(doctorAddress);
      await tx.wait();
      showAlert("Doctor access revoked!", "success");
      await fetchAuthorizedProviders();
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Error revoking doctor access.";
      console.error("Error revoking doctor access:", error);
      showAlert(errorReason, "danger");
    }
  }

    // Fetch authorized providers
    async function fetchAuthorizedProviders() {
      try {
        const providers = await healthcareDAppContract.viewAuthorizedByMe();
        if (Array.isArray(providers) && providers.length === 3) {
          const [names, addresses, departments] = providers;
  
          const uniqueProvidersMap = new Map();
          addresses.forEach((address, index) => {
            if (!uniqueProvidersMap.has(address)) {
              uniqueProvidersMap.set(address, {
                name: names[index] || "N/A",
                address: address || "N/A",
                department: departments[index] || "N/A",
              });
            }
          });
  
          setAuthorizedProviders(Array.from(uniqueProvidersMap.values()));
          showAlert("Authorized providers fetched successfully.", "success");
        } else {
          throw new Error("Invalid data structure from contract.");
        }
      } catch (error) {
        const errorReason = error?.data?.data?.reason || "Unable to fetch authorized providers.";
        console.error("Error fetching authorized providers:", error);
        showAlert(errorReason, "danger");
      }
    }

    async function payBill(billingId, amount) {
      try {
        const tx = await healthcareDAppContract.payBill(billingId, { value: ethers.utils.parseEther(amount) });
        await tx.wait();
        showAlert("Bill paid successfully!", "success");
        fetchPatientProfile();
      } catch (error) {
        const errorReason = error?.data?.data?.reason || "Error paying bill.";
        console.error("Error paying bill:", error);
        showAlert(errorReason, "danger");
      }
    }
  
  // Filter doctors based on the search query
  const filteredDoctors = doctors.filter((doctor) =>
    doctor.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    doctor.specialization.toLowerCase().includes(searchQuery.toLowerCase()) ||
    doctor.address.toLowerCase().includes(searchQuery.toLowerCase())
  );

// Labratory related code 
// Fetch lab bills for the patient
async function fetchLabBills() {
  setLoading(true);
  try {
    // Call the backend function to fetch lab bills
    const [bills, testIndexes] = await roleManagementContract.viewLabBills(account);

    // Format the bills for display
    const formattedBills = bills.map((bill, index) => ({
      billingId: bill.billingId.toString(), // Unique bill ID
      description: bill.description, // Test description
      amount: ethers.utils.formatEther(bill.amount), // Convert amount to ETH
      isPaid: bill.isPaid, // Paid status
      timestamp: bill.timestamp
        ? new Date(parseInt(bill.timestamp.toString()) * 1000).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
        : "N/A",
      testIndex: testIndexes[index]?.toString() || null, // Map back to the test index
    }));

    setLabBills(formattedBills); // Update state
    showAlert("Lab bills fetched successfully!", "success");
  } catch (error) {
    console.error("Error fetching lab bills:", error);
    showAlert("Failed to fetch lab bills.", "danger");
  } finally {
    setLoading(false);
  }
}

async function payLabBill(billIndex) {
  const selectedBill = labBills[billIndex];
  if (!selectedBill || selectedBill.isPaid) {
    showAlert("Invalid or already paid bill selected.", "danger");
    return;
  }

  setLoading(true);
  setCurrentBillId(selectedBill.billingId); // Track the current bill being paid

  try {
    // Call the backend function to update the test status and mark the bill as paid
    const tx = await roleManagementContract.updateTestStatus(
      account, // Patient address
      selectedBill.testIndex, // Use the correct testIndex
      "Completed", // New status
      "Payment completed", // Remarks
      {
        value: ethers.utils.parseEther(selectedBill.amount), // Payment amount in ETH
      }
    );
    await tx.wait();

    // Update local state to reflect the payment
    const updatedBills = [...labBills];
    updatedBills[billIndex].isPaid = true;
    setLabBills(updatedBills);

    showAlert("Bill paid successfully!", "success");
  } catch (error) {
    console.error("Error paying lab bill:", error);
    showAlert("Failed to pay the bill. Please try again.", "danger");
  } finally {
    setLoading(false);
    setCurrentBillId(null); // Reset current bill tracking
  }
}




const toggleTimestampSort = () => {
  setSortAscending((prevState) => !prevState);
};

// Handle checkbox selection
const handleCheckboxChange = (billIndex) => {
  setSelectedTestIndexes((prevIndexes) =>
    prevIndexes.includes(billIndex)
      ? prevIndexes.filter((index) => index !== billIndex) // Remove if already selected
      : [...prevIndexes, billIndex] // Add if not selected
  );
};

const toggleSection = (section) => {
  setVisibleSection((prevSection) => (prevSection === section ? null : section));
};

useEffect(() => {
  if (account) {
    fetchLabBills();
  }
}, [account]);

function calculateTotalAmount() {
  return selectedTestIndexes.reduce((total, index) => {
    const selectedBill = labBills[index];
    return total + (selectedBill ? parseFloat(selectedBill.amount) : 0);
  }, 0).toFixed(4);
}

const fetchAuthorizedPharmacists = async () => {
  setLoadingPharmacists(true);
  try {
      const pharmacists = await roleManagementContract.viewAllAuthorizedPharmacists();
      const formattedPharmacists = pharmacists.map((pharmacist) => ({
        name: pharmacist[0],
        address: pharmacist[1],
        location: pharmacist[2],
        supervisor: pharmacist[3],
      }));
    setAuthorizedPharmacists(formattedPharmacists);
    showAlert("Authorized pharmacists fetched successfully.", "success");
  } catch (error) {
    console.error("Error fetching authorized pharmacists:", error);
    showAlert("Unable to fetch authorized pharmacists.", "danger");
  } finally {
    setLoadingPharmacists(false);
  }
};

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/patient.png"
        title="Welcome to Patient Profile Page"
        subTitle="Your Partner in Health and Wellness"
      />
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" currentAccount={account} />
  
      <div className="container mt-5">
        <Alert
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />
        <Section>
          {error && <div className="alert alert-danger">{error}</div>}
  
          {/* Patient Information Section */}
          {patientProfile && (
            <div className="card shadow-sm border-primary mb-4 rounded-lg">
              <div className="card-body">
                <h4 className="card-title text-primary mb-3">Patient Information</h4>
                <div className="row">
                  <div className="col-md-6">
                    <p><strong>Name:</strong> {patientProfile.name}</p>
                    <p><strong>Number:</strong> {patientProfile.number}</p>
                    <p><strong>Date:</strong> {patientProfile.date}</p>
                  </div>
                  <div className="col-md-6">
                    <p><strong>Age:</strong> {patientProfile.age}</p>
                    <p><strong>Gender:</strong> {patientProfile.gender}</p>
                    <p><strong>Health Problem:</strong> {patientProfile.problem}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
  
         {/* Past Medical Records Section */}
{/* Past Medical Records Section */}
<div className="card shadow-sm border-primary mb-4 rounded-lg">
  <div className="card-body">
    <h4 className="card-title text-primary mb-3">Prescriptions & Past Medical Records</h4>
    <button onClick={fetchPastMedicalRecords} className="btn btn-info w-100 mb-3 rounded-pill">
      Fetch Past Medical Records
    </button>
    {loadingPastRecords && (
      <div className="text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )}
    {pastMedicalRecords.length > 0 ? (
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Health Problem</th>
              <th>Medicine</th>
              <th>Dosage</th>
              <th>Doctor</th>
              <th>Fulfilled</th>
              <th
                onClick={() => {
                  // Sort by timestamp (descending)
                  const sortedRecords = [...pastMedicalRecords].sort((a, b) => {
                    const dateA = new Date(a.timestamp);
                    const dateB = new Date(b.timestamp);
                    return dateB - dateA; // Descending order
                  });
                  setPastMedicalRecords(sortedRecords); // Update the records state
                }}
                style={{ cursor: "pointer" }}
              >
                Timestamp <i className="fa fa-sort"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {pastMedicalRecords
              .sort((a, b) => {
                // Sort unfulfilled first, then by timestamp descending
                if (a.fulfilled === "No" && b.fulfilled === "Yes") return -1;
                if (a.fulfilled === "Yes" && b.fulfilled === "No") return 1;
                const dateA = new Date(a.timestamp);
                const dateB = new Date(b.timestamp);
                return dateB - dateA; // Descending order
              })
              .map((record, index) => (
                <tr key={index}>
                  <td>{record.healthProblem || "N/A"}</td>
                  <td>{record.medicine || "N/A"}</td>
                  <td>{record.dosage || "N/A"}</td>
                  <td>{record.doctorName || "N/A"}</td>
                  <td>
                    {record.fulfilled === "Yes" ? (
                      <span className="badge bg-success">Yes</span>
                    ) : (
                      <span className="badge bg-danger">No</span>
                    )}
                  </td>
                  <td>
                    {record.timestamp
                      ? new Date(record.timestamp).toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        })
                      : "N/A"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    ) : (
      !loadingPastRecords && (
        <p className="text-center text-muted">
          No past medical records / prescriptions found.
        </p>
      )
    )}
  </div>
</div>

  
          {/* Search and List Authorized Doctors Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary">Search Authorized Doctors</h4>
              <input
                type="text"
                className="form-control mb-3 rounded-pill"
                placeholder="Search doctors by name, specialization, or address"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button onClick={fetchAvailableDoctors} className="btn btn-primary w-100 mb-3 rounded-pill">
                Fetch Available Doctors
              </Button>
              {filteredDoctors.length > 0 ? (
                <ul className="list-group">
                  {filteredDoctors.map((doctor, index) => (
                    <li key={index} className="list-group-item">
                      <p><strong>Name:</strong> {doctor.name}</p>
                      <p><strong>Specialization:</strong> {doctor.specialization}</p>
                      <p><strong>Address:</strong> {doctor.address}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No doctors found matching the search criteria.</p>
              )}
            </div>
          </div>
  
          {/* Authorized Providers Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary mb-3">Authorized Providers</h4>
              <Button onClick={fetchAuthorizedProviders} className="btn-primary w-100 mb-3 rounded-pill">
                Fetch Authorized Providers
              </Button>
              {authorizedProviders.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Department</th>
                      </tr>
                    </thead>
                    <tbody>
                      {authorizedProviders.map((provider, index) => (
                        <tr key={index}>
                          <td>{provider.name}</td>
                          <td>{provider.address}</td>
                          <td>{provider.department}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No authorized providers found.</p>
              )}
            </div>
          </div>
  
          {/* Manage Doctor Access Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary mb-3">Manage Doctor Access</h4>
              <select
                value={selectedDoctor}
                onChange={(e) => setSelectedDoctor(e.target.value)}
                className="form-control mb-3 rounded-pill"
              >
                <option value="">Select a Doctor</option>
                {doctors.map((doctor, index) => (
                  <option key={index} value={doctor.address}>
                    {doctor.name} - {doctor.specialization}
                  </option>
                ))}
              </select>
              <div className="d-flex">
                <Button onClick={() => grantDoctorAccess(selectedDoctor)} className="btn-primary flex-fill mr-2 rounded-pill">
                  Grant Access
                </Button>
                <Button onClick={() => revokeDoctorAccess(selectedDoctor)} className="btn-danger flex-fill rounded-pill">
                  Revoke Access
                </Button>
              </div>
            </div>
          </div>
  
         {/* Bills Section */}
<div className="card shadow-sm border-primary mb-4 rounded-lg">
  <div className="card-body">
    <h4 className="card-title text-primary mb-3">Bills</h4>

    {/* Fetch Bills Button */}
    <button
      className="btn btn-info btn-sm mb-3 rounded-pill"
      onClick={fetchPatientProfile} // Call the function to fetch updated bills
      disabled={loading} // Disable button while loading
    >
      {loading ? (
        <span className="spinner-border spinner-border-sm me-2"></span>
      ) : (
        "Fetch Bills"
      )}
    </button>

    {/* Display Bills */}
    {/* Display Bills */}
    {bills.length > 0 ? (
  <div className="table-responsive">
    <table className="table table-striped table-bordered">
      <thead className="table-dark">
        <tr>
          <th>Bill #</th>
          <th>Remarks</th>
          <th>Pharmacist</th>
          <th>Location</th>
          <th>Amount (ETH)</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {bills
          .sort((a, b) => {
            // Sort unpaid bills first
            if (!a.isPaid && b.isPaid) return -1;
            if (a.isPaid && !b.isPaid) return 1;
            return 0; // Maintain existing order for bills with the same status
          })
          .map((bill, index) => (
            <tr key={index}>
              {/* Ensure billingId is displayed correctly */}
              <td>{bill.billingId ? parseInt(bill.billingId) + 1 : "N/A"}</td>

              <td>{bill.description}</td>
              <td>{bill.pharmacistName || "Unknown"}</td>
              <td>{bill.location || "N/A"}</td>
              {/* Format amount using ethers */}
              <td>{bill.amount ? parseFloat(bill.amount).toFixed(4) : "0.0000"}</td>
              <td>
                {bill.isPaid ? (
                  <span className="badge bg-success">Paid</span>
                ) : (
                  <span className="badge bg-danger">Unpaid</span>
                )}
              </td>
              <td>
                {!bill.isPaid ? (
                  <button
                    className="btn btn-primary btn-sm rounded-pill"
                    onClick={() => payBill(bill.billingId, bill.amount)} // Call payBill function
                    disabled={loading && currentBillId === bill.billingId}
                  >
                    {loading && currentBillId === bill.billingId ? (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    ) : (
                      "Pay"
                    )}
                  </button>
                ) : (
                  <button className="btn btn-secondary btn-sm" disabled>
                    Paid
                  </button>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
) : (
  <p className="text-center text-muted">No pending bills. Click "Fetch Bills" to reload.</p>
)}


  </div>
</div>

    {/* Lab Bills Section */}
<div className="card shadow-sm border-primary mb-4 rounded-lg">
  <div className="card-body">
    <h4 className="card-title text-primary mb-3">Lab Bills</h4>
    {/* Button to Fetch Bills */}
    <button
      className="btn btn-info btn-sm mb-3 rounded-pill"
      onClick={fetchLabBills}
      disabled={loading}
    >
      {loading ? (
        <span className="spinner-border spinner-border-sm me-2"></span>
      ) : (
        "Fetch Lab Bills"
      )}
    </button>

    {/* Display Bills Table */}
    {labBills.length > 0 ? (
  <div className="table-responsive">
    <table className="table table-striped table-hover table-bordered">
      <thead className="table-dark">
        <tr>
          <th>Bill ID</th>
          <th>Description</th>
          <th>Amount (ETH)</th>
          <th>Status</th>
          <th
            style={{ cursor: "pointer" }}
            onClick={toggleTimestampSort} // Sorting toggle
          >
            Timestamp <span className="">(Click to Sort)</span>
          </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {labBills
          .sort((a, b) => {
            if (!a.isPaid && b.isPaid) return -1;
            if (a.isPaid && !b.isPaid) return 1;

            // Correctly parse and compare timestamps
            const dateA = new Date(a.timestamp.replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3'));
            const dateB = new Date(b.timestamp.replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3'));

            return sortAscending ? dateA - dateB : dateB - dateA;
          })
          .map((bill, index) => {
            const description = bill.description.split(" - ")[0];
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{description}</td>
                <td>{parseFloat(bill.amount).toFixed(4)} ETH</td>
                <td>
                  {bill.isPaid ? (
                    <span className="badge bg-success">Paid</span>
                  ) : (
                    <span className="badge bg-danger">Unpaid</span>
                  )}
                </td>
                <td>{bill.timestamp || "N/A"}</td>
                <td>
                  {!bill.isPaid ? (
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => payLabBill(index)}
                      disabled={loading && currentBillId === bill.billingId}
                    >
                      {loading && currentBillId === bill.billingId ? (
                        <span className="spinner-border spinner-border-sm me-2"></span>
                      ) : (
                        "Pay Bill"
                      )}
                    </button>
                  ) : (
                    <button className="btn btn-secondary btn-sm" disabled>
                      Paid
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
) : (
  <p className="text-center text-muted">No pending lab bills found. Click "Fetch Lab Bills" to reload</p>
)}


  </div>
</div>





          {/* Update Health Problem and Doctor Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary mb-3">Update Health Problem and Doctor</h4>
              <input
                type="text"
                placeholder="New Health Problem"
                value={newProblem}
                onChange={(e) => setNewProblem(e.target.value)}
                className="form-control mb-3 rounded-pill"
              />
              <select
                value={selectedDoctor}
                onChange={(e) => setSelectedDoctor(e.target.value)}
                className="form-control mb-3 rounded-pill"
              >
                <option value="">Select a Doctor</option>
                {doctors.map((doctor, index) => (
                  <option key={index} value={doctor.address}>
                    {doctor.name} - {doctor.specialization}
                  </option>
                ))}
              </select>
              <Button onClick={updateProblemAndDoctor} className="btn-success w-100 rounded-pill">
                Update
              </Button>
            </div>
          </div>
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
          <div className="card-body">
            <h4 className="card-title text-primary mb-3">Authorized Pharmacists</h4>
            <Button
              onClick={fetchAuthorizedPharmacists}
              className="btn-primary w-100 mb-3 rounded-pill"
              disabled={loadingPharmacists}
            >
              {loadingPharmacists ? (
                <Spinner animation="border" size="sm" role="status" className="me-2" />
              ) : null}
              Fetch Authorized Pharmacists
            </Button>
            {authorizedPharmacists.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className="table-dark">
                    <tr>
                      <th>Name</th>
                      <th>Location</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorizedPharmacists.map((pharmacist, index) => (
                      <tr key={index}>
                        <td>{pharmacist.name}</td>
                        <td>{pharmacist.location}</td>
                        <td>{pharmacist.address}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              !loadingPharmacists && <p>No authorized pharmacists found.</p>
            )}
          </div>
        </div>

        </Section>
      </div>
      <br />
      <Footer />
    </>
  );
}

export default PatientProfile;