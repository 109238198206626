import Web3 from 'web3';

export async function addNetwork(type, setAlertProps) {
  let web3Obj;

  // Detect Web3 provider
  if (typeof window.ethereum !== 'undefined') {
    web3Obj = new Web3(window.ethereum);
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error) {
      setAlertProps({
        type: 'warning',
        message: 'User denied account access. Please allow MetaMask to connect.',
      });
      return;
    }
  } else if (typeof window.web3 !== 'undefined') {
    web3Obj = new Web3(window.web3.givenProvider);
  } else {
    setAlertProps({
      type: 'danger',
      message: 'No compatible Web3 browser detected! Please install MetaMask.',
    });
    return;
  }

  if (web3Obj) {
    try {
      const network = await web3Obj.eth.net.getId();
      const netID = network.toString();
      let params;

      // Configure network parameters
      if (type === 'holesky') {
        if (netID === '17000') {
          setAlertProps({
            type: 'info',
            message: 'Holesky Test Network is already added to MetaMask.',
          });
          return;
        } else {
          params = {
            chainId: '0x4268',
            chainName: 'Holesky Test Network',
            nativeCurrency: {
              name: 'ETH',
              symbol: 'ETH',
              decimals: 18,
            },
            rpcUrls: ['https://rpc.holesky.ethpandaops.io/'],
            blockExplorerUrls: ['https://holesky.etherscan.io/'],
          };
        }
      } else if (type === 'morph') {
        if (netID === '2810') {
          setAlertProps({
            type: 'info',
            message: 'Morph Holesky Test Network is already added to MetaMask.',
          });
          return;
        } else {
          params = {
            chainId: '0xAFA',
            chainName: 'Morph Holesky Test Network',
            nativeCurrency: {
              name: 'ETH',
              symbol: 'ETH',
              decimals: 18,
            },
            rpcUrls: ['https://rpc-quicknode-holesky.morphl2.io'],
            blockExplorerUrls: ['https://explorer-holesky.morphl2.io/'],
          };
        }
      }

      if (params) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [params],
        });
        setAlertProps({
          type: 'success',
          message: `${type === 'holesky' ? 'Holesky' : 'Morph Holesky'} Testnet added successfully!`,
        });
      }
    } catch (error) {
      setAlertProps({
        type: 'danger',
        message: 'Failed to add the network. Please try again later.',
      });
    }
  }
}
