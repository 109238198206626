import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NewsletterForm({ label, btnText, btnArrowUrl }) {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/feedback', { state: { email } }); // Navigate to feedback page with email in state
  };

  return (
    <>
      {label && <p>Your Email</p>}
      <form onSubmit={handleSubmit} className="cs_newsletter_form">
        <input
          type="text"
          className="cs_form_field"
          placeholder="example@email.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit" className="cs_btn cs_style_1">
          <span>{btnText}</span>
          <i>
            <img src={btnArrowUrl} alt="Icon" />
            <img src={btnArrowUrl} alt="Icon" />
          </i>
        </button>
      </form>
    </>
  );
}
