import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';  // Ensure BrowserRouter is only here
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import './sass/index.scss';
import { ThemeProvider } from './components/ThemeContext';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <BrowserRouter>

<ThemeProvider>
    <App />
  </ThemeProvider>,
     
    </BrowserRouter>
  
);
