import React, { useState } from "react";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import BannerSectionStyle2 from "./Section/BannerSection/BannerSectionStyle2";
import Alert from "./Alert"; // Import the reusable alert component
import { pageTitle } from "../helpers/PageTitle";

function PharmacistRegistration({ roleManagementContract , account }) {
  pageTitle("Pharmacist Registration");

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [key, setKey] = useState("");
  const [alertProps, setAlertProps] = useState({ type: "", message: "" });

  // Register the pharmacist with the provided details and security key
  async function registerPharmacist(e) {
    e.preventDefault();
    try {
      const tx = await roleManagementContract.registerPharmacist(
        name,
        location,
        key,
        { value: ethers.utils.parseEther("0.01") } // Required fee
      );
      await tx.wait();
      setAlertProps({
        type: "success",
        message: "Pharmacist registered successfully!",
      });
    } catch (error) {
      const errorReason =
        error?.data?.data?.reason || "Error registering pharmacist.";
      setAlertProps({ type: "danger", message: errorReason });
    }
  }

  return (
    <>
      <BannerSectionStyle2
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to Pharmacist Registration Page"
        subTitle="Your Partner in Health and Wellness"
      />
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" currentAccount={account} />
      <br />
      <br />
      <div className="container min-vh-100 d-flex justify-content-center align-items-center">
        <div
          className="card shadow-lg p-5 rounded-lg border-0"
          style={{ maxWidth: "500px", width: "100%", backgroundColor: "#f9fafc" }}
        >
          <div className="card-body">
            <h2 className="card-title text-center mb-4 text-primary fw-bold">
              Pharmacist Registration
            </h2>

            {/* Reusable Alert Component */}
            <Alert
              type={alertProps.type}
              message={alertProps.message}
              onClose={() => setAlertProps({ type: "", message: "" })}
            />

            {/* Pharmacist Registration Form */}
            <form onSubmit={registerPharmacist} className="form">
              <div className="form-group mb-4">
                <label className="form-label text-secondary fw-semibold">Full Name</label>
                <input
                  type="text"
                  className="form-control rounded-pill shadow-sm border-primary"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter full name"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label text-secondary fw-semibold">Location</label>
                <textarea
                  className="form-control shadow-sm border-primary rounded"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="e.g., 123 Pharmacy St., City, Country"
                  rows="3"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label text-secondary fw-semibold">Security Key</label>
                <input
                  type="password"
                  className="form-control rounded-pill shadow-sm border-primary"
                  placeholder="Enter Security Key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  required
                />
              </div>
              <div className="d-grid">
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill shadow-sm fw-bold py-2"
                >
                  Register Pharmacist (0.01ETH)
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default PharmacistRegistration;
