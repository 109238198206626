import React from 'react';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import TextWidget from '../Widget/TextWidget';
import { ThemeContext } from '../ThemeContext';
import { useContext } from 'react';
const menuDataOne = [
  { title: 'About Us', href: '/about' },
  { title: 'Departments', href: '/departments' },
  { title: 'Doctors', href: '/doctors' },
  { title: 'Dashboard', href: '/dashboard' },
  { title: 'Appointment', href: '/appointments' },
  { title: 'Developers', href: '/dev-section' },
];
const menuDataTwo = [
  { title: 'Feedback', href: '/feedback' },
  { title: 'FAQs', href: '/' },
  { title: 'Privacy Policy', href: '/' },
  { title: 'Terms and Conditions', href: '/' },
];

export default function Footer() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: 'url(/images/footer_bg_1.svg)' }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: 'url(/images/footer_logo_bg.svg)' }}
        >
          <img
            src="/images/logo_icon.svg"
            alt="Logo Icon"
            className="cs_footer_brand_icon"
          />
          <h2 className="cs_footer_brand_text">ProHealth</h2>
        </div>
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text="ProHealth Medical & <br />Healthcare Center" />
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="User Feedback"
                  subTitle="Your Opinion Matters!, Share your feedback with us and help improve our services."
                />
                <div>
                <div className="theme-toggle-section text-center mt-4">
        <button
          onClick={toggleTheme}
          className={`btn ${theme === 'light' ? 'btn-dark' : 'btn-light'} rounded-pill`}
          style={{
            background: theme === 'light' ? 'linear-gradient(135deg, #8e44ad, #3498db)' : 'linear-gradient(135deg, #f1c40f, #e74c3c)',
            color: '#fff',
            padding: '10px 20px',
            fontWeight: 'bold',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
            fontSize: '0.9rem',
            marginTop: '10px',
          }}
        >
          {theme === 'light' ? '🌙 Dark Mode' : '☀️ Light Mode'}
        </button>
      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
            <div className="cs_copyright">
              Copyright © 2024 - 2025 Pro Health. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
