import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DropDown from "./DropDown";

export default function Header({ logoSrc, variant, currentAccount }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // New state variable

  useEffect(() => {
    // Function to handle screen resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Utility function to truncate the address
  const truncateAddress = (address) => {
    if (!address) return "Not Connected";
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <>
      <header
        className={`cs_site_header cs_style1 cs_sticky_header ${
          mobileToggle ? "cs_mobile_toggle_active" : ""
        } ${variant} ${isSticky ? "cs_active_sticky" : ""}`}
      >
        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="cs_main_header_left">
                {/* Responsive Logo */}
                <Link className="cs_site_branding" to="/">
                  <img src={logoSrc} alt="Logo" className="mt-4" />
                </Link>
                <nav className="cs_nav">
                  <ul
                    className={`${
                      mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                    }`}
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/doctors">Find Doctor</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/">Pages</Link>
                      <DropDown>
                        <ul>
                          <li>
                            <Link to="/appointments">Appointments</Link>
                          </li>
                          <li>
                            <Link to="/departments">Departments</Link>
                          </li>
                          <li>
                            <Link to="/doctors">Doctors</Link>
                          </li>
                          <li>
                            <Link to="/gallery">Gallery</Link>
                          </li>
                          <li>
                            <Link to="/dev-section">Developers</Link>
                          </li>
                          <li>
                            <Link to="/register-doctor">Doctor Registration</Link>
                          </li>
                          <li>
                            <Link to="/register-pharmacist">
                              Pharmacist Registration
                            </Link>
                          </li>
                          
                        </ul>
                      </DropDown>
                    </li>
                    <li>
                      <Link to="/feedback">Feedback</Link>
                    </li>
                    <li>
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs_menu_toggle cs_teggle_active"
                        : "cs_menu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </nav>
              </div>
              <div className="cs_main_header_right">
                {/* Conditionally render account badge */}
                {!isMobile && currentAccount && (
                  <div className="account-badge-wrapper">
                    <div className="account-badge">
                      {truncateAddress(currentAccount)}
                    </div>
                    <div className="tooltip">{currentAccount}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Custom Styles */}
      <style>
        {`
          .account-badge-wrapper {
            position: relative;
            display: inline-block;
          }

          .account-badge {
            display: inline-block;
            background-color: white;
            color: #333;
            padding: 5px 15px;
            border-radius: 25px;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
            white-space: nowrap;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: background-color 0.3s, color 0.3s;
          }

          .account-badge:hover {
            background-color: #f8f9fa;
            color: #000;
          }

          .tooltip {
            display: none;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #333;
            color: #fff;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
            white-space: nowrap;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }

          .account-badge-wrapper:hover .tooltip {
            display: block;
          }
        `}
      </style>
    </>
  );
}
