import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../sass/index.scss";
import "./Admindash.css";

import { ethers } from "ethers";
import Header from "./Header";
import Footer from "./Footer";
import BannerSectionStyle5 from "./Section/BannerSection/BannerSectionStyle5";
import Alert from "./Alert";
import { pageTitle } from "../helpers/PageTitle";
function AdminDashboard({ contractOwner, roleManagementContract , account }) {
  pageTitle('Admin Dashboard');
  const [balance, setBalance] = useState("0"); // State to store contract balance
  const [newAdmin, setNewAdmin] = useState(""); // State to store new admin address
  const [doctors, setDoctors] = useState([]);
  const [showModal, setShowModal] = useState(false); // State to toggle modal
  const [showContacts, setShowContacts] = useState(true); // New state to toggle contact section visibility
  const [pharmacists, setPharmacists] = useState([]);
  const [contacts, setContacts] = useState([]); // New state for contacts
  const [loading, setLoading] = useState(false);
  const [authorizedDoctors, setAuthorizedDoctors] = useState({});
  const [authorizedPharmacists, setAuthorizedPharmacists] = useState({});
  const [securityKey, setSecurityKey] = useState("");
  const [newSecurityKey, setNewSecurityKey] = useState("");
  const [transactionInProgress, setTransactionInProgress] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
    fetchDoctorsAndPharmacists();
    fetchContacts(); // Fetch contacts on load
    fetchSecurityKey();
    fetchBalance();
  }, []);

  const departmentNames = [
    "Cardiology",
    "Neurology",
    "Dermatology",
    "Pediatrics",
    "Orthopedics",
    "Oncology",
    "Radiology",
    "Surgery",
    "General Practice"
  ];

  // Function to show alert
  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
  };

  // Fetch doctors, pharmacists, and contacts
  async function fetchDoctorsAndPharmacists() {
    setLoading(true);
    try {
      const doctorList = await roleManagementContract.viewAllDoctors();
      const pharmacistList = await roleManagementContract.viewAllPharmacists();

      setDoctors(doctorList);
      setPharmacists(pharmacistList);

      const doctorStatuses = {};
      for (const doctor of doctorList) {
        const isAuthorized = await roleManagementContract.authorizedDoctors(doctor.doctorAddress);
        doctorStatuses[doctor.doctorAddress] = isAuthorized;
      }
      setAuthorizedDoctors(doctorStatuses);

      const pharmacistStatuses = {};
      for (const pharmacist of pharmacistList) {
        const isAuthorized = await roleManagementContract.authorizedPharmacists(pharmacist.pharmacistAddress);
        pharmacistStatuses[pharmacist.pharmacistAddress] = isAuthorized;
      }
      setAuthorizedPharmacists(pharmacistStatuses);
    } catch (error) {
      showAlert("Error fetching data.", "danger");
    }
    setLoading(false);
  }
  // Function to handle admin change
  const handleChangeAdmin = async () => {
    setTransactionInProgress(true);
    try {
      const tx = await roleManagementContract.changeAdmin(newAdmin);
      await tx.wait();
      showAlert("Admin changed successfully!", "success");
      setNewAdmin(""); // Clear input field
    } catch (error) {
      showAlert("Error changing admin: " + error.message, "danger");
    } finally {
      setTransactionInProgress(false);
      setShowModal(false); // Close modal
    }
  };
 // Fetch the contract balance
const fetchBalance = async () => {
  try {
    const balance = await roleManagementContract.getBalance(); // Balance in wei
    const balanceInEther = ethers.utils.formatEther(balance); // Convert wei to Ether
    setBalance(balanceInEther);
    console.log(balanceInEther);
  } catch (error) {
    showAlert("Error fetching balance: " + error.message, "danger");
  }
};



  // Transfer the contract balance to the contract owner
  const transferBalance = async () => {
    setTransactionInProgress(true);
    try {
      const tx = await roleManagementContract.withdraw();
      await tx.wait();
      showAlert("Balance transferred successfully!", "success");
      fetchBalance(); // Update balance after transfer
    } catch (error) {
      showAlert("Error transferring balance: " + error.message, "danger");
    }
    setTransactionInProgress(false);
  };

 // Change the admin address
 const changeAdmin = async () => {
  if (!newAdmin) {
    showAlert("Please enter a valid address.", "danger");
    return;
  }
  setTransactionInProgress(true);
  try {
    const tx = await roleManagementContract.changeAdmin(newAdmin);
    await tx.wait();
    showAlert("Admin changed successfully!", "success");
  } catch (error) {
    showAlert("Error changing admin: " + error.message, "danger");
  }
  setTransactionInProgress(false);
};


  // Fetch contacts from the blockchain
  async function fetchContacts() {
    try {
      const contactList = await roleManagementContract.getContacts();
      const reversedContacts = [...contactList].reverse(); // Create a copy and then reverse it
      setContacts(reversedContacts);
    } catch (error) {
      showAlert("Error fetching contacts.", "danger");
    }
}


async function deleteContact(index) {
  try {
   

    const tx = await roleManagementContract.deleteContact(index);
    await tx.wait();

    setSuccessMessage("Contact deleted successfully!");
    fetchContacts(); // Refresh contacts list
  } catch (error) {
    setErrorMessage("Error deleting contact.");
  }
}

  // Fetch the security key
  async function fetchSecurityKey() {
    try {
      const key = await roleManagementContract.getSecurityKey();
      setSecurityKey(key);
    } catch (error) {
      console.log(error);
      showAlert("Error fetching security key.", "danger");
    }
  }

  // Update the security key
  async function updateSecurityKey() {
    setTransactionInProgress(true);
    try {
      const tx = await roleManagementContract.updateSecurityKey(newSecurityKey);
      await tx.wait();
      showAlert("Security key updated successfully!", "success");
      setSecurityKey(newSecurityKey);
    } catch (error) {
      showAlert("Error updating security key: " + error.message, "danger");
    }
    setTransactionInProgress(false);
  }

  // Authorize doctor
  async function authorizeDoctor(doctorAddress) {
    setTransactionInProgress(true);
    try {
      const tx = await roleManagementContract.authorizeDoctor(doctorAddress);
      await tx.wait();
      showAlert("Doctor authorized successfully!", "success");
      setAuthorizedDoctors((prev) => ({ ...prev, [doctorAddress]: true }));
    } catch (error) {
      showAlert("Error authorizing doctor: " + error.message, "danger");
    }
    setTransactionInProgress(false);
  }

  // Deauthorize doctor
  async function deauthorizeDoctor(doctorAddress) {
    setTransactionInProgress(true);
    try {
      await roleManagementContract.deauthorizeDoctor(doctorAddress);
      showAlert("Doctor deauthorized successfully!", "success");
      setAuthorizedDoctors((prev) => ({ ...prev, [doctorAddress]: false }));
    } catch (error) {
      showAlert("Error deauthorizing doctor: " + error.message, "danger");
    }
    setTransactionInProgress(false);
  }

  // Delete doctor
  async function deleteDoctor(doctorAddress) {
    setTransactionInProgress(true);
    try {
      await roleManagementContract.deleteDoctor(doctorAddress);
      showAlert("Doctor deleted successfully!", "success");
      setDoctors((prev) => prev.filter((doc) => doc.doctorAddress !== doctorAddress));
    } catch (error) {
      showAlert("Error deleting doctor: " + error.message, "danger");
    }
    setTransactionInProgress(false);
  }

  // Authorize pharmacist
  async function authorizePharmacist(pharmacistAddress) {
    setTransactionInProgress(true);
    try {
      const tx = await roleManagementContract.authorizePharmacist(pharmacistAddress);
      await tx.wait();
      showAlert("Pharmacist authorized successfully!", "success");
      setAuthorizedPharmacists((prev) => ({ ...prev, [pharmacistAddress]: true }));
    } catch (error) {
      showAlert("Error authorizing pharmacist: " + error.message, "danger");
    }
    setTransactionInProgress(false);
  }

  // Deauthorize pharmacist
  async function deauthorizePharmacist(pharmacistAddress) {
    setTransactionInProgress(true);
    try {
      await roleManagementContract.deauthorizePharmacist(pharmacistAddress);
      showAlert("Pharmacist deauthorized successfully!", "success");
      setAuthorizedPharmacists((prev) => ({ ...prev, [pharmacistAddress]: false }));
    } catch (error) {
      showAlert("Error deauthorizing pharmacist: " + error.message, "danger");
    }
    setTransactionInProgress(false);
  }

  // Delete pharmacist
  async function deletePharmacist(pharmacistAddress) {
    setTransactionInProgress(true);
    try {
      await roleManagementContract.deletePharmacist(pharmacistAddress);
      showAlert("Pharmacist deleted successfully!", "success");
      setPharmacists((prev) => prev.filter((ph) => ph.pharmacistAddress !== pharmacistAddress));
    } catch (error) {
      showAlert("Error deleting pharmacist: " + error.message, "danger");
    }
    setTransactionInProgress(false);
  }
  const toggleContactsVisibility = () => {
    setShowContacts(!showContacts);
  };

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/admin.png"
        title="Welcome to Admin Dashboard"
        subTitle="Your Partner in Health and Wellness"
      />
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" currentAccount={account} />

      <div className="container mt-5">
        {/* Alert Component */}
        <Alert
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />

        {/* Loading Spinner */}
        {loading ? (
          <div className="text-center mb-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading Admin Dashboard...</span>
          </div>
          <div className="mt-3">
            <strong>Loading Admin Dashboard...</strong>
          </div>
        </div>
        
        ) : (
          <>
            {/* Security Key Section */}
            <div className="card p-4 mb-5 shadow-lg border rounded custom-outline">
              <h4 className="mb-4 text-info">🔒 Security Key Management</h4>
              <p>
                Current Key:{" "}
                <span className="fw-bold text-warning">{securityKey}</span>
              </p>
              <input
                type="text"
                className="form-control mb-3 shadow border-0"
                placeholder="Enter new security key"
                value={newSecurityKey}
                onChange={(e) => setNewSecurityKey(e.target.value)}
              />
              <button
                className="btn btn-gradient w-100"
                onClick={updateSecurityKey}
                disabled={transactionInProgress}
              >
                {transactionInProgress ? (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                ) : null}
                {transactionInProgress ? "Updating..." : "Update Security Key"}
              </button>
            </div>
    {/* Contract Balance Section */}
    <div className="card p-4 mb-5 shadow-lg border rounded custom-outline">
          <h4 className="mb-4 text-info">💰 Contract Balance</h4>
          <p>
            Current Balance:{" "}
            <span className="fw-bold text-warning">{balance} ETH</span>
          </p>
          <button
            className="btn btn-gradient w-100"
            onClick={transferBalance}
            disabled={transactionInProgress}
          >
            {transactionInProgress ? (
              <span className="spinner-border spinner-border-sm me-2"></span>
            ) : null}
            {transactionInProgress ? "Transferring..." : "Transfer Balance"}
          </button>
        </div>
{/* Change Admin Section */}
<div className="card p-4 mb-5 shadow-lg border rounded custom-outline">
        <h4 className="mb-4 text-info">🛠 Change Admin</h4>
        <input
          type="text"
          className="form-control mb-3 shadow border-0"
          placeholder="Enter new admin address"
          value={newAdmin}
          onChange={(e) => setNewAdmin(e.target.value)}
        />
        <button
          className="btn btn-gradient w-100"
          onClick={() => setShowModal(true)} // Open confirmation modal
          disabled={transactionInProgress || !newAdmin}
        >
          {transactionInProgress ? (
            <span className="spinner-border spinner-border-sm me-2"></span>
          ) : null}
          {transactionInProgress ? "Changing..." : "Change Admin"}
        </button>
      </div>

   {/* Modernized Responsive Bootstrap Modal */}
<div
  className={`modal fade ${showModal ? "show d-block" : ""}`}
  tabIndex="-1"
  role="dialog"
  style={{
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Darkened backdrop
  }}
>
  <div className="modal-dialog modal-dialog-centered modal-md" role="document">
    <div className="modal-content border-0 shadow-lg rounded-3">
      {/* Modal Header */}
      <div className="modal-header bg-gradient-primary text-white">
        <h5 className="modal-title fw-bold text-center w-100">
          ⚠️ Confirm Admin Change
        </h5>
        <button
          type="button"
          className="btn-close btn-close-white"
          onClick={() => setShowModal(false)}
          aria-label="Close"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body py-4 px-5">
        <div className="text-center">
          <p className="fs-5 mb-4">
            Are you sure you want to change the admin to:
          </p>
          <p
            className="fw-bold text-primary bg-light py-3 px-4 rounded text-break shadow-sm"
            style={{
              maxWidth: "100%",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap", // Ensures proper wrapping
            }}
          >
            {newAdmin}
          </p>
          <p className="text-danger fw-semibold mt-4">
            🚨 This action is irreversible. Double-check the address.
          </p>

          {/* Get Info of Address Button */}
          <button
            className="btn btn-outline-success btn-sm mt-3"
            onClick={() => {
              const explorerUrl = `https://explorer-holesky.morphl2.io/address/${newAdmin}`;
              if (newAdmin) {
                window.open(explorerUrl, "_blank");
              } else {
                alert("Please enter a valid address to fetch info.");
              }
            }}
          >
            Get Info of Address
          </button>
        </div>
      </div>

      {/* Modal Footer */}
      <div className="modal-footer justify-content-center border-0">
        <button
          type="button"
          className="btn btn-outline-secondary btn-lg px-4"
          onClick={() => setShowModal(false)} // Close modal
          disabled={transactionInProgress}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-danger btn-lg px-4"
          onClick={handleChangeAdmin} // Confirm admin change
          disabled={transactionInProgress}
        >
          {transactionInProgress ? (
            <span className="spinner-border spinner-border-sm me-2"></span>
          ) : null}
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>


    
           {/* Doctors Management */}
<div className="card p-4 mb-5 shadow-lg border rounded custom-outline">
  <h4 className="mb-4 text-info">👨‍⚕️ Manage Doctors</h4>
  <ul className="list-group list-group-flush">
    {doctors.map((doctor, index) => (
      <li
        key={index}
        className="list-group-item bg-dark text-light border-light p-3 d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div className="doctor-profile">
          <p className="mb-1"><strong>Name:</strong> {doctor.name}</p>
          <p className="mb-1"><strong>Qualification:</strong> {doctor.qualification}</p>
          <p className="mb-1"><strong>Specialization:</strong> {doctor.specialization}</p>
          <p className="mb-1"><strong>Department:</strong> {departmentNames[doctor.department]}</p>
          <p className="mb-1"><strong>Contract Info:</strong> {doctor.contractInfo}</p>
        </div>
        <div className="d-flex flex-wrap mt-3 mt-md-0">
          {authorizedDoctors[doctor.doctorAddress] ? (
            <button
              className="btn btn-outline-danger btn-sm me-2 mb-2 mb-md-0"
              onClick={() => deauthorizeDoctor(doctor.doctorAddress)}
              disabled={transactionInProgress}
            >
              Deauthorize
            </button>
          ) : (
            <button
              className="btn btn-outline-success btn-sm me-2 mb-2 mb-md-0"
              onClick={() => authorizeDoctor(doctor.doctorAddress)}
              disabled={transactionInProgress}
            >
              Authorize
            </button>
          )}
          <button
            className="btn btn-outline-warning btn-sm me-2 mb-2 mb-md-0"
            onClick={() => deleteDoctor(doctor.doctorAddress)}
            disabled={transactionInProgress}
          >
            Delete
          </button>
        </div>
      </li>
    ))}
  </ul>
</div>
{/* Pharmacists Management */}
<div className="card p-4 mb-5 shadow-lg border rounded custom-outline">
  <h4 className="mb-4 text-info">💊 Manage Pharmacists</h4>
  <ul className="list-group list-group-flush">
    {pharmacists.map((pharmacist, index) => (
      <li
        key={index}
        className="list-group-item bg-dark text-light border-light p-3 d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div>
          <p className="mb-1"><strong>Name:</strong> {pharmacist.name}</p>
          <p className="mb-1"><strong>Location:</strong> {pharmacist.location}</p>
        </div>
        <div className="d-flex flex-wrap mt-3 mt-md-0">
          {authorizedPharmacists[pharmacist.pharmacistAddress] ? (
            <button
              className="btn btn-outline-danger btn-sm me-2 mb-2 mb-md-0"
              onClick={() => deauthorizePharmacist(pharmacist.pharmacistAddress)}
              disabled={transactionInProgress}
            >
              Deauthorize
            </button>
          ) : (
            <button
              className="btn btn-outline-success btn-sm me-2 mb-2 mb-md-0"
              onClick={() => authorizePharmacist(pharmacist.pharmacistAddress)}
              disabled={transactionInProgress}
            >
              Authorize
            </button>
          )}
          <button
            className="btn btn-outline-warning btn-sm me-2 mb-2 mb-md-0"
            onClick={() => deletePharmacist(pharmacist.pharmacistAddress)}
            disabled={transactionInProgress}
          >
            Delete
          </button>
        </div>
      </li>
    ))}
  </ul>
</div>




            {/* Feedback Section */}
            <button
              className="btn btn-outline-secondary mb-3"
              onClick={toggleContactsVisibility}
            >
              {showContacts ? "Hide Feedback Submissions" : "Show Feedback Submissions"}
            </button>

            {showContacts && (
              <div className="card p-4 mb-5 shadow-lg border rounded custom-outline">
                <h4 className="mb-4 text-info">📢 Feedback Submissions</h4>
                <ul className="list-group list-group-flush">
                  {contacts.map((contact, index) => (
                    <li
                      key={index}
                      className="list-group-item bg-dark text-light border-light"
                    >
                      <p><strong>Name:</strong> {contact.name}</p>
                      <p><strong>Email:</strong> {contact.email}</p>
                      <p><strong>Wallet Address:</strong> {contact.walletAddress}</p>
                      <p><strong>Subject:</strong> {contact.subject}</p>
                      <p><strong>Message:</strong> {contact.message}</p>
                      <p><strong>Timestamp:</strong> {new Date(contact.timestamp * 1000).toLocaleString()}</p>
                      <button
                        className="btn btn-outline-danger btn-sm mt-2"
                        onClick={() => deleteContact(index)}
                        disabled={transactionInProgress}
                      >
                        Delete
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>

      <br />
      <br />
      <Footer />
    </>
  );
}

export default AdminDashboard;