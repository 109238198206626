import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ethers } from "ethers";
import { roleManagementAddress, healthcareDAppAddress } from '../../abis/adders';
import HealthcareDAppABI from '../../abis/HealthcareDApp.json';
import RoleManagementABI from '../../abis/RoleManagement.json';
import Alert from '../Alert';
import { FaUser, FaPhone, FaCalendarAlt, FaStethoscope, FaNotesMedical ,FaUserMd } from 'react-icons/fa';
export default function AppointmentForm() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    age: '',
    gender: '',
    healthProblem: '',
    doctor: '',
    department: '',
    preferredDate: ''
  });
  const [errors, setErrors] = useState({});
  const [authorizedDoctors, setAuthorizedDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const nameRegex = /^[a-zA-Z ]+\d{4}$/;

  const formatDate = (date) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData((prevData) => ({
      ...prevData,
      preferredDate: formatDate(date)
    }));
  };

  useEffect(() => {
    async function initBlockchain() {
      if (!window.ethereum) {
        showAlert("Please install MetaMask to use this application.", "danger");
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      const roleManagementContract = new ethers.Contract(roleManagementAddress, RoleManagementABI, signer);
      const healthcareDAppContract = new ethers.Contract(healthcareDAppAddress, HealthcareDAppABI, signer);

      setFormData((prevData) => ({
        ...prevData,
        healthcareDAppContract,
        roleManagementContract,
      }));
    }

    initBlockchain();
  }, []);

  const fetchDoctorsByDepartment = async (department) => {
    setLoading(true);
    try {
      const departmentIndex = getDepartmentIndex(department);
      const { roleManagementContract } = formData;
      const [names, , specializations, , profilePics, addresses] =
        await roleManagementContract.getAuthorizedDoctorsByDepartment(departmentIndex);

      const doctorsList = names.map((name, index) => ({
        name,
        specialization: specializations[index],
        profilePic: profilePics[index],
        doctorAddress: addresses[index],
      }));
      setAuthorizedDoctors(doctorsList);
    } catch (error) {
      console.error("Error fetching doctors by department:", error);
      showAlert("Error fetching doctors by department.", "danger");
    } finally {
      setLoading(false);
    }
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartment = event.target.value;
    setFormData((prevData) => ({ ...prevData, department: selectedDepartment }));
    fetchDoctorsByDepartment(selectedDepartment);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true to indicate submission in progress
    setAlertMessage(""); // Clear any previous alert messages

    const newErrors = {};

    if (!formData.name || !nameRegex.test(formData.name)) {
        newErrors.name = 'Name is required and must end with 4 digits.';
    }
    if (!formData.phoneNumber) {
        newErrors.phoneNumber = 'Phone number is required.';
    }
    if (!formData.age) {
        newErrors.age = 'Age is required.';
    }
    if (!formData.gender) {
        newErrors.gender = 'Gender is required.';
    }
    if (!formData.healthProblem) {
        newErrors.healthProblem = 'Please describe your health problem.';
    }
    if (!formData.doctor) {
        newErrors.doctor = 'Please select a doctor.';
    }
    if (!formData.department) {
        newErrors.department = 'Please select a department.';
    }
    if (!formData.preferredDate) {
        newErrors.preferredDate = 'Preferred date is required.';
    }

    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        setLoading(false); // Reset loading state if validation fails
        return;
    }

    try {
        setErrors({});
        const tx = await formData.healthcareDAppContract.registerPatient(
            formData.name,
            formData.phoneNumber,
            formData.preferredDate,
            formData.age,
            formData.gender,
            formData.doctor,
            formData.healthProblem,
        );
        await tx.wait();
        showAlert("Patient registered successfully!", "success");

        // Reset form after successful submission
        setFormData({
            name: '',
            phoneNumber: '',
            age: '',
            gender: '',
            healthProblem: '',
            doctor: '',
            department: '',
            preferredDate: ''
        });
        setSelectedDate(null);
    } catch (error) {
        showAlert("Error registering patient: " + error.message, "danger");
    } finally {
        setLoading(false); // Reset loading state after submission
    }
};

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getDepartmentIndex = (department) => {
    const departments = {
      "Cardiology": 0,
      "Neurology": 1,
      "Dermatology": 2,
      "Pediatrics": 3,
      "Orthopedics": 4,
      "Oncology": 5,
      "Radiology": 6,
      "Surgery": 7,
      "General Practice": 8,
    };
    return departments[department];
  };
  return (
    <div>
      <Alert
        type={alertType}
        message={alertMessage}
        onClose={() => setAlertMessage("")}
      />
      <form onSubmit={handleSubmit} className="row">
        <style>{`
          .error {
            border-color: red;
          }
          .error-message {
            color: red;
            font-size: 0.875rem;
            margin-top: -10px;
            margin-bottom: 10px;
          }
          .cs_form_field, .cs_form_field select, .cs_with_icon_input input {
            background-color: #333;
            color: #f8f9fa;
            padding: 12px 40px 12px 20px; /* Extra padding for icon */
            border: none;
            border-radius: 25px;
            outline: none;
            font-size: 1rem;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.3s ease, background-color 0.3s ease;
          }
          .cs_form_field:focus, .cs_with_icon_input input:focus {
            background-color: #444;
            box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
          }
          .cs_form_field::placeholder {
            color: #adb5bd;
          }
          .cs_input_label {
            font-weight: 600;
            color: #adb5bd;
            margin-bottom: 8px;
            display: block;
          }
          .cs_btn {
            background: linear-gradient(135deg, #007bff, #6610f2);
            color: white;
            padding: 12px 20px;
            border: none;
            border-radius: 25px;
            font-weight: 600;
            font-size: 1rem;
            transition: background 0.3s ease, box-shadow 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .cs_btn:hover {
            background: linear-gradient(135deg, #6610f2, #007bff);
            box-shadow: 0 4px 10px rgba(0, 123, 255, 0.4);
          }
          .icon-wrapper {
            position: relative;
          }
          .icon-wrapper .input-icon {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            color: #adb5bd;
          }
        `}</style>

        {/* Name */}
        <div className="col-lg-6 m-2 icon-wrapper">
          <label className="cs_input_label">Name (with last 4 digits of number)</label>
          <FaUser className="input-icon" />
          <input
            type="text"
            className={`cs_form_field bg-secondary ${errors.name ? 'error' : ''}`}
            placeholder="David John1234"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <div className="error-message m-2">{errors.name}</div>}
        </div>

        {/* Phone Number */}
        <div className="col-lg-6 m-2 icon-wrapper">
          <label className="cs_input_label">Phone Number</label>
          <FaPhone className="input-icon" />
          <input
            type="text"
            className={`cs_form_field bg-secondary ${errors.phoneNumber ? 'error' : ''}`}
            placeholder="(123) 456 - 789"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          {errors.phoneNumber && <div className="error-message m-2">{errors.phoneNumber}</div>}
        </div>

        {/* Age */}
        <div className="col-lg-6 m-2">
          <label className="cs_input_label">Age</label>
          <input
            type="number"
            className={`cs_form_field bg-secondary ${errors.age ? 'error' : ''}`}
            name="age"
            value={formData.age}
            onChange={handleChange}
            placeholder="Enter age"
          />
          {errors.age && <div className="error-message m-2">{errors.age}</div>}
        </div>

        {/* Gender */}
        <div className="col-lg-6 m-2">
          <label className="cs_input_label">Gender</label>
          <select
            className={`cs_form_field bg-secondary ${errors.gender ? 'error' : ''}`}
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          {errors.gender && <div className="error-message m-2">{errors.gender}</div>}
        </div>

        {/* Preferred Date */}
        <div className="col-lg-6 m-2 icon-wrapper">
          <label className="cs_input_label">Preferred Date</label>
          <FaCalendarAlt className="input-icon" />
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            isClearable
            placeholderText="dd/mm/yyyy"
            className="cs_form_field bg-secondary"
          />
          {errors.preferredDate && <div className="error-message m-2">{errors.preferredDate}</div>}
        </div>

        {/* Health Problem */}
        <div className="col-lg-6 m-2 icon-wrapper">
          <label className="cs_input_label">Health Problem</label>
          <FaNotesMedical className="input-icon" />
          <input
            type="text"
            className={`cs_form_field bg-secondary ${errors.healthProblem ? 'error' : ''}`}
            placeholder="Describe your health problem"
            name="healthProblem"
            value={formData.healthProblem}
            onChange={handleChange}
          />
          {errors.healthProblem && <div className="error-message m-2">{errors.healthProblem}</div>}
        </div>

        {/* Department */}
        <div className="col-lg-6 m-2 icon-wrapper">
          <label className="cs_input_label">Department</label>
          <FaStethoscope className="input-icon" />
          <select
            className={`cs_form_field bg-secondary ${errors.department ? 'error' : ''}`}
            name="department"
            value={formData.department}
            onChange={handleDepartmentChange}
          >
            <option value="">Select Department</option>
            <option value="Cardiology">Cardiology</option>
            <option value="Neurology">Neurology</option>
            <option value="Pediatrics">Pediatrics</option>
            <option value="Dermatology">Dermatology</option>
            <option value="Orthopedics">Orthopedics</option>
            <option value="Radiology">Radiology</option>
            <option value="Surgery">Surgery</option>
            <option value="General Practice">General Practice</option>
          </select>
          {errors.department && <div className="error-message m-2">{errors.department}</div>}
        </div>

        {/* Select Doctor */}
        <div className="col-lg-6 m-2 icon-wrapper">
          <label className="cs_input_label">Select Doctor</label>
          <FaUserMd className="input-icon" />
          <select
            className={`cs_form_field bg-secondary ${errors.doctor ? 'error' : ''}`}
            name="doctor"
            value={formData.doctor}
            onChange={handleChange}
            disabled={loading}
          >
            <option value="">Select a Doctor</option>
            {authorizedDoctors.map((doctor, index) => (
              <option key={index} value={doctor.doctorAddress}>
                {doctor.name} ({doctor.specialization})
              </option>
            ))}
          </select>
          {errors.doctor && <div className="error-message m-2">{errors.doctor}</div>}
        </div>

       
        {/* Submit Button */}
<div className="col-lg-12 m-2">
  <button
    type="submit"
    className="cs_btn m-2 cs_style_1"
    disabled={loading} // Disable the button when loading is true
  >
    <span>{loading ? "Submitting..." : "Submit"}</span>
    <i>
      <img src="/images/icons/arrow_white.svg" alt="Icon" />
      <img src="/images/icons/arrow_white.svg" alt="Icon" />
    </i>
  </button>
</div>

      </form>
    </div>
  );
}