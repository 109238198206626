import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // To capture the URL parameter
import { ethers } from 'ethers';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import Section from '../Section';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import DoctorDetailsSection from '../Section/DoctorDetailsSection';
import AppointmentSectionStyle2 from '../Section/AppointmentSection/AppointmentSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
import RoleManagementABI from '../../abis/RoleManagement.json'; // Ensure ABI is imported

export default function DoctorDetails({ roleManagementContract  }) {



  let { addr } = useParams(); // Capture the URL parameter

  const [doctorDetails, setDoctorDetails] = useState(null); // Doctor details state
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null);
  // Decode the Base64 encoded address
  // const decodeBase64Address = (base64) => {
  //   try {
  //     console.log(base64);
  //     const decodedString = atob(String(base64)); // Decode base64 string
  //     console.log(decodedString);
  //     return String(decodedString);
  //   } catch (error) {
  //     console.error('Error decoding Base64 string:', error);
  //     return null;
  //   }
  // };

  // Function to fetch doctor details from the blockchain
  const fetchDoctorDetails = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!addr) throw new Error('Invalid doctor address.');

      // Fetch data from smart contract
      const doctorData = await roleManagementContract.getDoctorDetails(addr);

      // Fetch IPFS data (assuming the IPFS URL is stored in doctorData[4])
      const ipfsResponse = await fetch(doctorData[4]);
      if (!ipfsResponse.ok) {
        throw new Error(`Failed to fetch IPFS data: ${ipfsResponse.statusText}`);
      }

      const ipfsData = await ipfsResponse.json();

      setDoctorDetails({
        imgUrl: ipfsData.doctor.profilePic || '/images/default-profile.png', // fallback image
        name: doctorData[0],
        department: doctorData[3],
        specialization: doctorData[2],
        description: ipfsData.doctor.description || 'No description available.',
        social: ipfsData.doctor.socialLinks || {},
        schedules: ipfsData.doctor.schedules || [],
        degrees: ipfsData.doctor.degrees || [],
        experiences: ipfsData.doctor.experiences || [],
        awards: ipfsData.doctor.awardsAchievements || [],
        contract: doctorData[5],
      });
      setLoading(false);
    } catch (err) {
      console.error('Error fetching doctor details:', err);
      setError('Failed to load doctor details. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDoctorDetails();
  }, [addr]);

  if (loading) return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      fontSize: '1.5rem',
      color: '#007bff'
    }}>
      <div style={{
        animation: 'spin 1s linear infinite',
        marginRight: '10px',
        border: '4px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '4px solid #007bff',
        width: '24px',
        height: '24px'
      }} />
      Loading doctor details...
    </div>
  );
  
  // Inline CSS for animation
  const spinAnimation = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `;
  
  // Inject the animation style directly into the document head
  document.head.insertAdjacentHTML('beforeend', `<style>${spinAnimation}</style>`);
  
  if (error) return <div>{error}</div>;
  if (!doctorDetails) return <div>No doctor details available.</div>;

  pageTitle(doctorDetails.name || 'Doctor Details');

  return (
    <>
      <BreadcrumbStyle2 />
      <Section bottomMd={190} bottomLg={150} bottomXl={110}>
        <DoctorDetailsSection
          bgUrl="/images/doctors/doctor_details_bg.svg"
          imgUrl={doctorDetails.imgUrl}
          name={doctorDetails.name}
          department={doctorDetails.department}
          designation={`${doctorDetails.specialization} Specialist`}
          description={doctorDetails.description}
          social={[
            { icon: 'fa6-brands:facebook-f', href: doctorDetails.social?.facebook || '#' },
            { icon: 'fa6-brands:linkedin-in', href: doctorDetails.social?.linkedin || '#' },
            { icon: 'fa6-brands:twitter', href: doctorDetails.social?.twitter || '#' },
          ]}
          contactInfo={[
            { iconUrl: '/images/icons/call.svg', title: doctorDetails.contract }, // Sample static contact info
            { iconUrl: '/images/icons/envlope.svg', title: `${(doctorDetails.name).split(' ').join('')}@prohealth.com` }, // Sample static email
          ]}
          
          contactInfoHeading="Contact Info"
          schedules={[
            { day: 'Monday', time: '09.00-12.00' },
            { day: 'Wednesday', time: '15.00-18.00' },
            { day: 'Friday', time: '09.00-12.00' },
          ]}
          scheduleHeading="Appointment Schedules"
          degrees={
            doctorDetails.degrees?.map(degree => ({
              title: degree,
              subTitle: '', // You can add subtitles if needed
            })) || []
          }
          degreesHeading="Degrees"
          experiences={
            doctorDetails.experiences?.map(experience => ({
              title: experience,
            })) || []
          }
          experiencesHeading="Experiences"
          awards={
            doctorDetails.awards?.map(award => ({
              title: award,
            })) || []
          }
          awardHeading="Awards/Achievements"
        />
      </Section>
  
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="/images/home_2/appointment_img.png"
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
        />
      </Section>
  
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
  
}