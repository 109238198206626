import React, { useState } from 'react';
import { ethers } from 'ethers';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import RoleManagementABI from "../../abis/RoleManagement.json";
import DoctorDetails from './DoctorDetails';

const departmentEnum = {
  Cardiology: 0,
  Neurology: 1,
  Dermatology: 2,
  Pediatrics: 3,
  Orthopedics: 4,
  Oncology: 5,
  Radiology: 6,
  Surgery: 7,
  GeneralPractice: 8,
};

const reverseDepartmentEnum = {
  0: 'Cardiology',
  1: 'Neurology',
  2: 'Dermatology',
  3: 'Pediatrics',
  4: 'Orthopedics',
  5: 'Oncology',
  6: 'Radiology',
  7: 'Surgery',
  8: 'GeneralPractice',
};

export default function Doctors({ roleManagementContract }) {
  const [authorizedDoctors, setAuthorizedDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [error, setError] = useState(null); // Track error messages

  pageTitle('Doctors');

  const fetchWithTimeout = (url, timeout = 5000) => {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => reject(new Error('Fetch timed out')), timeout);
      fetch(url)
        .then(response => {
          clearTimeout(timer);
          resolve(response);
        })
        .catch(err => {
          clearTimeout(timer);
          reject(err);
        });
    });
  };

  const fetchDoctorsByDepartment = async (departmentName) => {
    try {
      setLoading(true);
      setError(null);

      const departmentIndex = departmentEnum[departmentName];
      const [names, qualifications, specializations, departments, ipfslink, doctorAddressesList] = await roleManagementContract.getAuthorizedDoctorsByDepartment(departmentIndex);

      const doctors = await Promise.all(
        doctorAddressesList.map(async (address, index) => {
          let profilePicUrl = 'https://picsum.photos/200/300'; // Default image
          let description = 'No description available.';
          let socialLinks = {};

          try {
            if (ipfslink && ipfslink[index]) {
              const ipfsResponse = await fetchWithTimeout(ipfslink[index], 7000);
              if (ipfsResponse.ok) {
                const ipfsData = await ipfsResponse.json();
                profilePicUrl = ipfsData.doctor.profilePic || profilePicUrl;
                description = ipfsData.doctor.description || description;
                socialLinks = ipfsData.doctor.socialLinks || socialLinks;
              } else {
                console.error(`Failed to fetch IPFS data for doctor at index ${index}`);
              }
            }
          } catch (error) {
            console.error(`Error fetching IPFS data for doctor at index ${index}:`, error);
          }

          return {
            imgUrl: profilePicUrl,
            name: `${names[index]}, ${qualifications[index]}`,
            department: departments[index],
            designation: `${specializations[index]} Specialist`,
            description,
            social: [
              { icon: 'fa6-brands:facebook-f', href: socialLinks.facebook || `/doctors/${address}` },
              { icon: 'fa6-brands:linkedin-in', href: socialLinks.linkedin || `/doctors/${address}` },
              { icon: 'fa6-brands:twitter', href: socialLinks.twitter || `/doctors/${address}` },
            ],
            href: `/doctors/doctor-details/${address}`,
          };
        })
      );

      setAuthorizedDoctors(doctors);
      setLoading(false);
    } catch (error) {
      setError('Error fetching doctors by department. Please try again.');
      setLoading(false);
    }
  };

  const getAllDoctorsDe = async () => {
    try {
      setLoading(true);
      setError(null);

      const doctorsData = await roleManagementContract.viewAllAuthorizedDoctors();

      const doctors = await Promise.all(
        doctorsData.map(async (doctorArray) => {
          let profilePicUrl = 'https://picsum.photos/200/300';
          let description = 'No description available.';
          let socialLinks = {};

          try {
            const ipfsResponse = await fetchWithTimeout(doctorArray[4], 7000);
            if (ipfsResponse.ok) {
              const ipfsData = await ipfsResponse.json();
              profilePicUrl = ipfsData.doctor.profilePic || profilePicUrl;
              description = ipfsData.doctor.description || description;
              socialLinks = ipfsData.doctor.socialLinks || socialLinks;
            } else {
              throw new Error('Failed to fetch IPFS data');
            }
          } catch (fetchError) {
            console.error('Error fetching data from IPFS for doctor:', fetchError);
          }

          return {
            imgUrl: profilePicUrl,
            name: doctorArray[0],
            department: reverseDepartmentEnum[doctorArray[3]],
            designation: doctorArray[2],
            description,
            social: [
              { icon: 'fa6-brands:facebook-f', href: socialLinks.facebook },
              { icon: 'fa6-brands:linkedin-in', href: socialLinks.linkedin },
              { icon: 'fa6-brands:twitter', href: socialLinks.twitter },
            ],
            href: doctorArray[6] ? `/doctors/doctor-details/${doctorArray[6]}` : '#',
          };
        })
      );

      const validDoctors = doctors.filter((doctor) => doctor !== null);
      setAuthorizedDoctors(validDoctors);
      setLoading(false);
    } catch (error) {
      setError('Error fetching all doctors. Please try again.');
      setLoading(false);
    }
  };

  const handleDepartmentClick = (department) => {
    setSelectedDepartment(department);
    if (department === "All") {
      getAllDoctorsDe();
    } else {
      fetchDoctorsByDepartment(department);
    }
  };


  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="/images/doctors/banner_img.png"
        title="Introduce You to <br />Our Experts"
        subTitle="The list of certified doctors with years of <br />professional experiences"
      />

      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110} className="text-center">
        {/* Button-based sorting UI */}
        <div className="d-flex align-items-center justify-content-center mb-4 flex-wrap">
        <span className="text-secondary fw-bold me-3 mb-2">Sort By:</span>
          {["All", "Cardiology", "Neurology", "Dermatology", "Pediatrics", "Orthopedics", "Oncology", "Radiology", "Surgery", "GeneralPractice"].map((department) => (
            <button
              key={department}
              className={`btn btn-outline-primary m-1 ${selectedDepartment === department ? "active" : ""}`}
              onClick={() => handleDepartmentClick(department)}
            >
              {department}
            </button>
          ))}
        </div>

        {/* Display loading spinner if still loading */}
        {loading && (
          <div className="d-flex justify-content-center my-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {/* Display doctors or message if no doctors found */}
        {!loading && authorizedDoctors.length > 0 && (
          <TeamSectionStyle2 data={authorizedDoctors} />
        )}
        {!loading && authorizedDoctors.length === 0 && (
  <div
    className="text-center"
    style={{
      backgroundColor: '#f0f4f8', // Soft gray-blue background
      color: '#333', // Darker text color for readability
      padding: '12px 20px',
      borderRadius: '8px', // Rounded corners for a modern look
      border: '1px solid #d1d5db', // Light border to frame the message
      fontWeight: '600', // Slightly bold text for emphasis
    }}
    role="alert"
  >
    No doctors found for this department.
  </div>
)}

      </Section>

      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="/images/doctors/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          className="text-center py-5"
        />
      </Section>
    </>
  );
}