import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';

export default function Layout({currentAccount}) {
  return (
    <>
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" currentAccount={currentAccount} />
      <Outlet />
      <Footer />
    </>
  );
}
