import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Doctors from './components/Pages/Doctors';
import Blog from './components/Pages/Blog';
import Appointments from './components/Pages/Appointments';
import Layout2 from './components/Layout/Layout2';
import Layout5 from './components/Layout/Layout5';
import HomeStyle5 from './components/Pages/HomeStyle5';
import HomeStyle6 from './components/Pages/HomeStyle6';
import HomeStyle2 from './components/Pages/HomeStyle2';
import HomeStyle3 from './components/Pages/HomeStyle3';
import HomeStyle4 from './components/Pages/HomeStyle4';
import Layout3 from './components/Layout/Layout3';
import Layout4 from './components/Layout/Layout4';
import Departments from './components/Pages/Departments';
import DepartmentDetails from './components/Pages/DepartmentDetails';
import BlogDetails from './components/Pages/BlogDetails';
import DoctorDetails from './components/Pages/DoctorDetails';
import PatientProfile from './components/Pages/PatientProfile';
import Gallery from './components/Pages/Gallery';
import Timetable from './components/Pages/Timetable';
import Contact from './components/Pages/Contact';
import ErrorPage from './components/Pages/ErrorPage';
import HealthcareDAppABI from './abis/HealthcareDApp.json';
import RoleManagementABI from './abis/RoleManagement.json';
import { ethers } from 'ethers';  
import DoctorDashboard from "./components/DoctorDashboard";
import PharmacistDashboard from "./components/PharmacistDashboard";
import PatientRegistration from "./components/PatientRegistration";
import AdminDashboard from "./components/AdminDashboard";
import DoctorRegistration from "./components/DoctorRegistration";
import PharmacistRegistration from "./components/PharmacistRegistration";
import { roleManagementAddress, healthcareDAppAddress } from './abis/adders';
import ConnectWallet from './components/ConnectWallet';
import { useEffect, useState ,useContext } from 'react';
import './App.css';

function App() {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [contractOwner, setContractOwner] = useState("");
  const [roleManagementContract, setRoleManagementContract] = useState(null);
  const [healthcareDAppContract, setHealthcareDAppContract] = useState(null);
 
  const targetNetwork = {
    chainId: '0xAFA', // Chain ID for Morph Holesky Testnet (2810 in hexadecimal)
    chainName: 'Morph Holesky Testnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH', // Symbol for the native currency
      decimals: 18,
    },
    rpcUrls: ['https://rpc-quicknode-holesky.morphl2.io'], // Morph Holesky Testnet RPC URL
    blockExplorerUrls: ['https://explorer-holesky.morphl2.io/'], // Morph Holesky Testnet Block Explorer URL
  };
  
  function switchNetwork() {
    if (window.ethereum) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: targetNetwork.chainId }],
      }).catch(async (error) => {
        if (error.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [targetNetwork],
          });
        } else {
          console.error("Error switching network:", error);
        }
      });
    } else {
      alert('MetaMask is not installed. Please install it to use this feature.');
    }
  }
  

  // Effect to check for connected wallet on page load
  useEffect(() => {
    if (window.ethereum) {
      connectWalletOnLoad();
      
      // Listen for account changes and update state accordingly
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          setCurrentAccount(accounts[0]);
        } else {
          setCurrentAccount(null); // Reset if no accounts connected
        }
      });
    }
  }, []);

  // Effect to load contract data once the wallet is connected
  useEffect(() => {
    if (currentAccount) {
      loadContractData();
    }
  }, [currentAccount]);

  // Automatically connect wallet if already connected
  async function connectWalletOnLoad() {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    if (accounts.length > 0) {
      setCurrentAccount(accounts[0]);
    }
  }

  async function connectWallet() {
    // Ensure the container for alerts exists
    let alertContainer = document.getElementById("alert-container");
    if (!alertContainer) {
      alertContainer = document.createElement("div");
      alertContainer.id = "alert-container";
      alertContainer.style.position = "fixed";
      alertContainer.style.top = "20px";
      alertContainer.style.right = "20px";
      alertContainer.style.zIndex = "1050";
      alertContainer.style.width = "100%";
      alertContainer.style.maxWidth = "400px";
      document.body.appendChild(alertContainer);
    }
  
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const address = accounts[0];
        const shortAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;
        console.log("Connected Account:", address);
  
       
        // Add interactivity to the wallet address display
        const walletAddress = document.querySelector(".wallet-address");
        const fullAddress = document.querySelector(".full-address");
        walletAddress.addEventListener("mouseenter", () => {
          fullAddress.style.display = "block";
        });
        walletAddress.addEventListener("mouseleave", () => {
          fullAddress.style.display = "none";
        });
      } catch (error) {
        
      }
    } else {
      // Display warning alert
      alertContainer.innerHTML = `
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          Please install MetaMask to use this application.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      `;
    }
  }
  
  
  

  // Function to load contract data and determine the role of the connected account
  async function loadContractData() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const roleManagementContract = new ethers.Contract(roleManagementAddress, RoleManagementABI, signer);
      const healthcareDAppContract = new ethers.Contract(healthcareDAppAddress, HealthcareDAppABI, signer);
      setRoleManagementContract(roleManagementContract);
      setHealthcareDAppContract(healthcareDAppContract);

      setLoading(true);

      // Fetch contract owner and compare with current account
      const owner = await roleManagementContract.contractOwner();
     
      setContractOwner(owner);

      if (currentAccount.toLowerCase() === owner.toLowerCase()) {
        setRole("admin");
        console.log("Role: Admin");
      } else {
        const isDoctor = await roleManagementContract.authorizedDoctors(currentAccount);
        if (isDoctor) {
          setRole("doctor");
          console.log("Role: Doctor");
        } else {
          const isPharmacist = await roleManagementContract.authorizedPharmacists(currentAccount);
          if (isPharmacist) {
            setRole("pharmacist");
            console.log("Role: Pharmacist");
          } else {
            const patient = await healthcareDAppContract.patients(currentAccount);
            if (patient && patient.name !== "") {
              setRole("patient");
              console.log("Role: Patient");
            } else {
              setRole("new");
              console.log("Role: New User");
            }
          }
        }
      }

      setLoading(false);
    } catch (error) {
      console.error("Error loading contract data:", error);
      setLoading(false);
    }
  }

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Conditionally render content based on wallet connection status
  if (!currentAccount) {
    return <ConnectWallet connectWallet={connectWallet} />;
  }

  

  return (
    <>


   
  
      {/* Main Routes */}
      <Routes>
        <Route
          path="/"
          element={<Layout roleManagementContract={roleManagementContract}  currentAccount = {currentAccount} />}
        >
          <Route index element={<Home roleManagementContract={roleManagementContract} />} />
          <Route path="about" element={<About />} />
          <Route path="doctors" element={<Doctors roleManagementContract={roleManagementContract} />} />
          <Route path="/doctors/doctor-details/:addr" element={<DoctorDetails roleManagementContract={roleManagementContract} />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:blogId" element={<BlogDetails />} />
          <Route path="appointments" element={<Appointments roleManagementContract={roleManagementContract} />} />
          <Route path="departments" element={<Departments />} />
          <Route path="departments/:departmentId" element={<DepartmentDetails />} />
          <Route path="pricing-plan" element={<PatientProfile account={currentAccount} roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="timetable" element={<Timetable />} />
          <Route path="feedback" element={<Contact />} />
        </Route>
        
        <Route 
          path="/register-doctor" 
          element={<DoctorRegistration roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} account = {currentAccount} />} 
        />
        <Route 
          path="/register-pharmacist" 
          element={<PharmacistRegistration roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} account = {currentAccount} />} 
        />
        <Route 
          path="/dev-section"
          element={<DepartmentDetails />}
          />
        <Route
          path="/dashboard"
          element={
            role === "doctor" ? (
              <DoctorDashboard account={currentAccount} roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract}  />
            ) : role === "pharmacist" ? (
              <PharmacistDashboard account={currentAccount} roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />
            ) : role === "new" ? (
              <Appointments roleManagementContract={roleManagementContract} account={currentAccount} />
            ) : role === "patient" ? (
              <PatientProfile account={currentAccount} roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />
            ) : role === "admin" ? (
              <AdminDashboard contractOwner={contractOwner} roleManagementContract={roleManagementContract} account={currentAccount} />
            ) : (
              <div className="alert-container d-flex justify-content-center align-items-center vh-100 bg-dark bg-opacity-75">
  <div
    className="alert-content card shadow-lg border-0 rounded-4 p-5 bg-white position-relative"
    style={{
      maxWidth: "450px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
    }}
  >
    {/* Badge */}
    <span
      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger shadow-sm"
      style={{
        zIndex: "1",
        fontSize: "0.8rem",
        padding: "0.6rem 1rem",
        marginTop: "-0.9rem",
        marginRight: "-1.2rem",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
      }}
    >
      Error
    </span>

    {/* Alert Icon */}
    <div className="d-flex align-items-center mb-4">
      <i
        className="bi bi-exclamation-triangle-fill text-warning display-5 me-3"
        style={{
          fontSize: "2.5rem",
          filter: "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.3))",
        }}
      ></i>
      <h4 className="fw-bold text-danger mb-0" style={{ fontSize: "1.5rem" }}>
        Unknown Network
      </h4>
    </div>

    {/* Alert Message */}
    <p
      className="mb-5 text-dark"
      style={{
        fontSize: "1rem",
        lineHeight: "1.5",
        textAlign: "center",
      }}
    >
      Please connect to the <strong> Morph Holesky Testnet</strong> to access the application.
    </p>

    {/* Buttons */}
    <div className="d-flex justify-content-center gap-3">
      <button
        className="btn btn-primary fw-bold w-50 d-flex align-items-center justify-content-center gap-2"
        onClick={switchNetwork}
        style={{
          borderRadius: "10px",
          padding: "0.8rem",
          boxShadow: "0px 4px 6px rgba(0, 123, 255, 0.3)",
          transition: "transform 0.3s ease, box-shadow 0.3s",
        }}
        onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
        onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
      >
        <i className="bi bi-arrow-repeat"></i> Switch Network
      </button>
      <button
        className="btn btn-secondary fw-bold w-50 d-flex align-items-center justify-content-center gap-2"
        onClick={() => window.location.reload()}
        style={{
          borderRadius: "10px",
          padding: "0.8rem",
          boxShadow: "0px 4px 6px rgba(108, 117, 125, 0.3)",
          transition: "transform 0.3s ease, box-shadow 0.3s",
        }}
        onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
        onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
      >
        <i className="bi bi-arrow-clockwise"></i> Refresh
      </button>
    </div>
  </div>
</div>

            

            )
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
  
}

export default App;