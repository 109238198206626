import React from "react";

export default function Alert({ type, message, onClose }) {
  if (!message) return null;

  return (
    <div
      className={`alert alert-${type} alert-dismissible fade show position-fixed`}
      style={{
        top: "0",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1050,
        width: "90%", // Optional: Adjust as needed
        maxWidth: "600px", // Optional: Adjust as needed
        marginTop: "10px", // Optional for spacing
      }}
      role="alert"
    >
      {message}
      <button
        type="button"
        className="btn-close"
        onClick={onClose}
        aria-label="Close"
      ></button>
    </div>
  );
}
