import React, { useState } from "react";
import { addNetwork } from "./AddNetwork"; // Update the path to where the addNetwork function is located
import Alert from "./Connectwalletalerts";
import "bootstrap/dist/css/bootstrap.min.css";

function ConnectWallet({ connectWallet }) {
  const [showNetworkOptions, setShowNetworkOptions] = useState(false);
  const [alertProps, setAlertProps] = useState({ type: "", message: "" }); // State for managing alerts

  const handleAddNetwork = async (network) => {
    try {
      await addNetwork(network, setAlertProps); // Pass `setAlertProps` to dynamically handle alerts
    } catch (error) {
      setAlertProps({
        type: "danger",
        message: `Error adding ${
          network === "holesky" ? "Holesky" : "Morph Holesky"
        } network. Please try again.`,
      });
      console.error(`Error adding ${network} network:`, error);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center bg-light" style={{ height: "100vh" }}>
      {/* Alert Notification */}
      <Alert
        type={alertProps.type}
        message={alertProps.message}
        onClose={() => setAlertProps({ type: "", message: "" })}
      />

      <div className="card shadow-lg border-0 p-4" style={{ maxWidth: "400px", borderRadius: "20px" }}>
        <div className="card-body text-center">
          <h2 className="card-title text-primary mb-3 fw-bold" style={{ fontSize: "1.8rem", letterSpacing: "0.5px" }}>
            Welcome to <span className="text-info">Healthcare DApp</span>
          </h2>
          <p className="card-text text-dark mb-4">
            Connect your wallet to access the decentralized healthcare platform and manage your medical interactions securely. Please connect to the{" "}
            <strong style={{ fontWeight: "bold", color: "black" }}>Morph Holesky Testnet.</strong>
          </p>

          <button
            onClick={connectWallet}
            className="btn btn-primary btn-lg w-100 d-flex align-items-center justify-content-center gap-2"
            style={{ borderRadius: "10px", transition: "transform 0.2s" }}
          >
            <i className="bi bi-wallet2"></i> Connect Wallet
          </button>

          {/* Toggle Button */}
          {!showNetworkOptions && (
            <button
              onClick={() => setShowNetworkOptions(true)}
              className="btn btn-outline-secondary btn-sm w-100 mt-3"
              style={{ borderRadius: "8px", fontSize: "0.85rem" }}
            >
              Don’t Have a Testnet?
            </button>
          )}

          {/* Display Add Networks and Get ETH Buttons */}
          {showNetworkOptions && (
            <div className="mt-3">
              <div className="d-flex gap-2">
                <button
                  onClick={() => handleAddNetwork("holesky")}
                  className="btn btn-info btn-sm w-50 d-flex align-items-center justify-content-center gap-2"
                  style={{ borderRadius: "8px", fontSize: "0.85rem" }}
                >
                  <i className="bi bi-gear"></i> Add Holesky Testnet
                </button>
                <button
                  onClick={() => handleAddNetwork("morph")}
                  className="btn btn-info btn-sm w-50 d-flex align-items-center justify-content-center gap-2"
                  style={{ borderRadius: "8px", fontSize: "0.85rem" }}
                >
                  <i className="bi bi-gear"></i> Add Morph Holesky
                </button>
              </div>
              <button
                onClick={() => window.open("https://cloud.google.com/application/web3/faucet/ethereum/holesky", "_blank")}
                className="btn btn-success btn-sm w-100 d-flex align-items-center justify-content-center gap-2 mt-2"
                style={{ borderRadius: "8px", fontSize: "0.85rem" }}
              >
                <i className="bi bi-currency-exchange"></i> Get Free Holesky Testnet ETH
              </button>

              {/* New Convert Button */}
              <button
                onClick={() => window.open("https://bridge-holesky.morphl2.io/", "_blank")}
                className="btn btn-warning btn-sm w-100 d-flex align-items-center justify-content-center gap-2 mt-2"
                style={{ borderRadius: "8px", fontSize: "0.85rem" }}
              >
                <i className="bi bi-arrow-repeat"></i> Convert Holesky to Morph
              </button>
            </div>
          )}

          <div className="mt-3 text-secondary" style={{ fontSize: "0.85rem" }}>
            Don’t have a wallet?{" "}
            <a
              href="https://metamask.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none fw-semibold text-info"
            >
              Get MetaMask
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectWallet;
